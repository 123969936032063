<template>
  <div class="editor-wrapper">
    <vue-editor
        v-model="editorContent"
        :style="cssVars"
        :disabled="disabled"
        :editorOptions="editorSettings"
    />
  </div>
</template>

<script>
import {VueEditor, Quill} from 'vue2-editor';

import ImageResize from 'quill-image-resize-module';

Quill.register('modules/imageResize', ImageResize);

export default {
  data: () => ({
    src: '',
    content: '',
    onDeep: true,
    editorSettings: {
      modules: {
        imageResize: {},
      },
    }
  }),
  props: {
    defaultContent: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: String,
      default: '540px',
    },
  },
  computed: {
    cssVars() {
      return {
        '--minHeight': this.customHeight,
      }
    },
    editorContent: {
      get() {
        return this.content
      },
      set(updatedContent) {
        this.$emit('on:editor-content:update', updatedContent)
      }
    },
  },
  components: {
    VueEditor
  },
  mounted() {
    this.content = this.defaultContent;
  }
};
</script>
<style lang="scss" src="@lazy/_editor.scss" scoped/>

