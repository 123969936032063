import { hydrate } from "@/utils/form";

export default class News {
  id = null;
  title = null;
  content = null;
  visibleFromDate = null;
  visibleToDate = null;
  enabled = true;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if(options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

}
