<template>
  <div>
    <d-body-top-bar
      :title="title"
    >
      <div slot="icons">
        <d-button
          text="admin.news.list.send-publication"
          class="ml-2 d-btn btn d-btn-danger font-text-title"
          @on:button-click="onSave"
        />
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <validation-observer
        ref="newsObserver"
        slim
      >
        <b-row>
          <b-col>
            <b-card>
              <b-row class="mb-2">
                <b-col cols="4">
                  <label class="field-name-class">
                    {{ $t('admin.news.list.title') }}
                  </label>
                  <d-text-field
                    v-model="news.title"
                    :rules="validation.title"
                    class-name="background-light-blue-inputs"
                    error-text="validation.text-input-three-characters"
                    size="sm"
                  />
                </b-col>
                <b-col cols="4">
                  <label class="field-name-class">
                    {{ $t('admin.news.list.publication-date') }}
                  </label>
                  <d-text-field
                    :value="news.visibleFromDate ? $moment(news.visibleFromDate).format('DD-MM-YYYY') : null"
                    :rules="validation.visibleFromDate"
                    class-name="background-light-blue-inputs"
                    error-text="validation.required"
                    size="sm"
                    @on:text-field:click="show=!show"
                  />
                  <d-calendar-modal
                    v-model="news.visibleFromDate"
                    identifier="fromDate"
                    :show="show"
                    @on:calendar-change="news.visibleFromDate = $event"
                  />
                </b-col>
                <b-col cols="4">
                  <label class="field-name-class">
                    {{ $t('admin.news.list.publication-end') }}
                  </label>
                  <d-text-field
                    :value="news.visibleToDate ? $moment(news.visibleToDate).format('DD-MM-YYYY') : null"
                    :rules="validation.visibleToDate"
                    class-name="background-light-blue-inputs"
                    error-text="validation.text-input-three-characters"
                    size="sm"
                    @on:text-field:click="showToDate=!showToDate"
                  />
                  <d-calendar-modal
                    v-model="news.visibleToDate"
                    identifier="toDate"
                    :show="showToDate"
                    @on:calendar-change="news.visibleToDate = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <label class="field-name-class">
                    {{ $t('admin.news.list.content') }}
                  </label>
                </b-col>
                <b-col cols="12">
                  <email-editor
                    v-if="isLoaded"
                    :is-empty="true"
                    :default-content="news.content"
                    @on:editor-content:update="news.content = $event"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </div>
</template>
<script>

import EmailEditor from "@custom/EmailEditor";
import News from "@/classes/News";
import {getNewsById, postNews, putNews} from "@api/services/news/news.api";

export default {
  components: {EmailEditor},
  data: () => ({
    news: new News(),
    show: false,
    validation: require('@validation/entities/News.json'),
    isLoaded: false,
    isSaving: false,
    showToDate: false,
  }),
  computed: {
    title() {
      return this.news.id ? this.$t('admin.news.actions.update') : this.$t('admin.news.actions.create');
    }
  },
  methods: {
    onSave() {
      if (!this.isSaving) {
        this.$refs.newsObserver.validate()
          .then((valid) => {
            if (valid) {
              this.isSaving = true;

              this.getNewsApi()
                .then((response) => {
                  this.$router.push({name: 'news'});
                })
                .finally(() => this.isSaving = false)
              ;
            }
          })
        ;
      }
    },
    getNewsApi() {
      this.formatNewsContent();

      return this.news.id ? putNews(this.news.id, this.news) : postNews(this.news);
    },
    formatNewsContent() {
      const centeredClasses = this.news.content.replaceAll("class=\"ql-align-center\"", "style=\"text-align: center\"");
      const leftClasses = centeredClasses.replaceAll("class=\"ql-align-left\"", "style=\"text-align: left\"");

      this.news.content = leftClasses.replaceAll("class=\"ql-align-right\"", "style=\"text-align: right\"");
    }
  },
  created() {
    this.isLoaded = false;

    if (this.$route.params.id) {
      getNewsById(this.$route.params.id)
        .then((response) => {
          this.news = new News(response.data, {serialize: true});
          this.isLoaded = true;
        })
      ;
    } else {
      this.isLoaded = true;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
